<template>
    <div>
        <div class="flex-column justify-center align-items-center" style="min-height:calc(100vh - 380px);">
            <div class="flex-column justify-center align-items-center">
                <div class="margin-bottom-30">
                    <i class="fa fa-unlink icon color-sub"></i>
                    <i class="fa fa-ban text-danger fa-2x color-red" style="margin-left:-30px"></i>
                </div>
            
                <div class="title">抱歉，出错啦！</div>
                <!-- <div class="bold font-size-24 margin-top-10">请联系客服经理开通权限！</div> -->
                <div class="flex-row line-height-large border-top border-bottom margin-top-20 margin-bottom-20 padding-top-20 padding-bottom-20 padding-left-40 padding-right-40">
                    <div>可能原因：</div>
                    <div>
                        <p>网络不稳定</p>
                        <p>数据服务异常</p>
                        <p>系统账号权限异常</p>
                        <p>安全软件禁止访问网络</p>
                    </div>
                </div>
                <div class="margin-bottom-30">
                    <el-button type="primary" size="medium" @click="goto">重试</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {  },
    data() {
        return {}
    },
    created() {
        
    },
    methods:{
        goto(){
            this.$router.replace({
                path: this.$route.query.redirect||'/',
            })
        }
    }
}
</script>
<style scoped>
    .icon{
        font-size: 100px;
    }
    .title{
        font-size: 44px;
        font-weight: bold;
    }
</style>